import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import Footer from '../components/Footer';
import Scroll from '../components/Scroll';
import logo from '../images/logo.png';

const IndexPage = () => (
  <Layout>
    <div id="wrapper">
      <section id="intro" className="wrapper style1 fullscreen fade-up">
        <div className="inner">
          <span className="image fit">
            <img src={logo} alt="Ecutuner.ca" />
          </span>
          <ul className="actions">
            <li>
              <Scroll type="id" element="three">
                <a href="#three" className="button">
                  Contact Us
                </a>
              </Scroll>
            </li>
          </ul>
        </div>
      </section>

      <section id="two" className="wrapper style3 fade-up">
        <div className="inner">
          <h2>Coming Soon!</h2>
          <p>
            We are working on a new website for 2020 to better serve our
            customers!
          </p>
        </div>
      </section>

      <section id="three" className="wrapper style1 fade-up">
        <div className="inner">
          <h2>Get in touch</h2>
          <p>Sign Up for updates and be the first to know when we launch!</p>
          <div className="split style1">
            <section>
              <form method="post" action="#">
                <div className="fields">
                  <div className="field half">
                    <label for="name">Name</label>
                    <input type="text" name="name" id="name" required />
                  </div>
                  <div className="field half">
                    <label for="email">Email</label>
                    <input type="text" name="email" id="email" required />
                  </div>
                  <div className="field">
                    <label for="message">Message</label>
                    <textarea name="message" id="message" rows="5" required />
                  </div>
                </div>
                <ul className="actions">
                  <li>
                    <a href="/#" className="button submit">
                      Submit
                    </a>
                  </li>
                </ul>
              </form>
            </section>
            <section>
              <ul className="contact">
                <li>
                  <h3>Social</h3>
                  <ul className="icons">
                    <li>
                      <a
                        href="https://www.facebook.com/ecutunerca/"
                        className="fa-facebook"
                      >
                        <span className="label">Facebook</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/ecutuner.ca/"
                        className="fa-instagram"
                      >
                        <span className="label">Instagram</span>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </section>
          </div>
        </div>
      </section>
    </div>

    <Footer />
  </Layout>
);

export default IndexPage;
